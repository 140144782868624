import { MOBILMEDIA } from './helper/constants';
import { $, $$, delegate } from './helper/utils';
import outline from './helper/outline';
import navigation from './components/navigation';
import search from './components/search/';

outline();
navigation();
search();

export default function () {
  let customSelectLoaded = false;

  const loadCustomSelect = () => {
    if ($('.custom-select') !== null) {
      import('./components/select.js').then((select) => {
        customSelectLoaded = true;
        return select.default();
      }).catch((err) => {
        console.warn('Chunk select.js loading failed', err);
      });
    }
  };

  // conditionaly load scripts that are only needed for certain viewports
  // but only if the viewport changed
  MOBILMEDIA.addListener((e) => {
    if (e.matches === false && customSelectLoaded === false) {
      loadCustomSelect();
    }
  });

  // conditionaly load scripts that are only needed for certain viewports
  if (MOBILMEDIA.matches === false) {
    loadCustomSelect();
  }

  if ($('.siema') !== null) {
    import('./components/slider.js')
      .then((slider) => slider.default())
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.gallery-grid-item') !== null) {
    import('./components/lightbox.js')
      .then((Lightbox) => Lightbox.default('.gallery-grid-item'))
      .catch((err) => {
        console.warn('Chunk lightbox.js loading failed', err);
      });
  }

  if ($('.nav-tabs') !== null || $('.accordion') !== null) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      TweenMax.fromTo(href, 0.3, { opacity: 0 }, { opacity: 1, ease: Linear.easeNone });
    };

    import('../libs/bs-4-native').then((bsn) => {
      if ('bootstrapNative' in window === false) {
        window.bootstrapNative = bsn;
      }
      const tabs = $$('.nav-tabs .tab-link');
      for (let i = 0; i < tabs.length; i++) {
        const el = tabs[i];
        // fade in new tab content
        el.addEventListener('hide.bs.tab', fadeTab, false);
      }
      const dHash = window.location.hash;
      // download tabs
      const dTabs = $$('.nav-tabs .tab-link.download');
      for (let i = 0; i < dTabs.length; i++) {
        const dEl = dTabs[i];
        const elTab = document.querySelector(dEl.getAttribute('href'));
        if (dHash.length) {
          dEl.classList.remove('active');
          elTab.classList.remove('active');
          if (dEl.getAttribute('href') === dHash) {
            dEl.classList.add('active');
            elTab.classList.add('active');
          }
        }
        // fade in new tab content
        dEl.addEventListener('hide.bs.tab', fadeTab, false);
      }
      // download accordion
      const dAccordions = $$('.download-accordion .accordion-item > .accordion-header a');

      if (dHash.indexOf('|') !== -1) {
        const dHashList = dHash.replace('#', '').split('|');
        for (let hi = 0; hi < dHashList.length; hi++) {
          const hash = `#${dHashList[hi]}`;
          for (let i = 0; i < dAccordions.length; i++) {
            const dEl = dAccordions[i];
            const elAccordion = document.querySelector(dEl.getAttribute('href'));
            if (hash.length) {
              if (dEl.getAttribute('href') === hash) {
                dEl.classList.remove('collapsed');
                elAccordion.classList.add('show');
              }
            }
          }
        }
      } else {
        for (let i = 0; i < dAccordions.length; i++) {
          const dEl = dAccordions[i];
          const elAccordion = document.querySelector(dEl.getAttribute('href'));
          if (dHash.length) {
            if (!dEl.classList.contains('collapsed')) {
              dEl.classList.add('collapsed');
              elAccordion.classList.remove('show');
            }
            if (dEl.getAttribute('href') === dHash) {
              dEl.classList.remove('collapsed');
              elAccordion.classList.add('show');
            }
          }
        }
      }
    }).catch((err) => {
      console.warn('Chunk bs4-native.js loading failed', err);
    });
  }

  // if ($('main table') !== null) {
  //   import('./components/table')
  //     .then(table => table.default())
  //     .catch((err) => {
  //       console.warn('Chunk table.js loading failed', err);
  //     });
  // }

  if ($('.filter-item') !== null) {
    import('./components/filter')
      .then((filter) => filter.default())
      .catch((err) => {
        console.warn('Chunk filter.js loading failed', err);
      });
  }

  if ($('.warn-msg') !== null) {
    import('./components/warning')
      .then((warning) => warning.default())
      .catch((err) => {
        console.warn('Chunk warning.js loading failed', err);
      });
  }

  if ($('.calculator.gas') !== null) {
    import('./components/calculators/gas')
      .then((gas) => gas.default())
      .catch((err) => {
        console.warn('Chunk gas.js loading failed', err);
      });
  }

  if ($('.calculator.electricity') !== null) {
    import('./components/calculators/electricity')
      .then((electricity) => electricity.default())
      .catch((err) => {
        console.warn('Chunk electricity.js loading failed', err);
      });
  }

  if ($('.calculation-wrapper #calculator-data') !== null) {
    import('./components/calculators/calculationGas')
      .then((calculationGas) => calculationGas.default())
      .catch((err) => {
        console.warn('Chunk calculationGas.js loading failed', err);
      });
  }

  if ($('.tooltip') !== null) {
    import('./components/calculators/tooltipModal')
      .then((tooltipModal) => tooltipModal.default())
      .catch((err) => {
        console.warn('Chunk tooltipModal.js loading failed', err);
      });
  }

  if ($('.application-form') !== null) {
    import('./components/applicationForm')
      .then((applicationForm) => applicationForm.default())
      .catch((err) => {
        console.warn('Chunk applicationForm.js loading failed', err);
      });
  }

  if ($('.event-form') !== null) {
    import('./components/eventForm')
      .then((eventForm) => eventForm.default())
      .catch((err) => {
        console.warn('Chunk eventForm.js loading failed', err);
      });
  }

  // slide down on home
  if ($('.home-banner-button') !== null) {
    const slideDown = () => {
      const startingY = window.pageYOffset;
      const diff = $('main .container').offsetTop - startingY;
      const duration = 500;
      let start;
      // Bootstrap our animation - it will get called right before next frame shall be rendered.
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        // Elapsed milliseconds since start of scrolling.
        const time = timestamp - start;
        // Get percent of completion in range [0, 1].
        const percent = Math.min(time / duration, 1);
        window.scrollTo(0, startingY + diff * percent);
        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
          window.requestAnimationFrame(step);
        }
      });
    };

    $('.home-banner-button').addEventListener('click', slideDown);
  }

  if ($('main a[href^="#"]:not([data-toggle])') !== null) {
    const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
    const importScripts = [];

    if (isSmoothScrollSupported === false) {
      importScripts.push(import('smoothscroll-polyfill'));
    }

    importScripts.push(import('./components/smoothScroll.js'));

    Promise.all(importScripts)
      .then((values) => {
        console.log(values);

        if (isSmoothScrollSupported === false) values[0].polyfill();
        values[importScripts.length - 1].default();
      })
      .catch((err) => {
        console.warn('Chunk table.js loading failed', err);
      });
  }

  if (window.downloadBox) {
    window.addEventListener('hashchange', () => {
      window.location.reload();
    });

    // window.addEventListener('popstate', () => {
    //   const { hash } = window.location;
    //   const el = $(`.download-accordion a[href="${hash}"]`);
    //   console.log({ hash, el });
    // });

    // eslint-disable-next-line
    function updateDownloadHash(e) {
      e.preventDefault();
      const oldUrl = `${window.location.pathname}${window.location.hash}`;
      const newUrl = `${window.location.pathname}${this.getAttribute('href')}`;

      if (newUrl !== oldUrl) {
        window.history.pushState({ newUrl }, '', newUrl);
      }
    }

    const downloadAccordions = $$('.download-accordion');
    for (let i = 0; i < downloadAccordions.length; i++) {
      const el = downloadAccordions[i];
      delegate(el, 'click', '.accordion-header a', updateDownloadHash);
    }
  }

  if ($('.calculators-teaser .cogs') !== null) {
    const teaserCards = $$('.calculators-teaser .card');

    for (let i = 0; i < teaserCards.length; i++) {
      const el = teaserCards[i];
      const tween = TweenMax.to(el.querySelectorAll('.cogs > path'), 2.5, {
        rotation: 360,
        transformOrigin: '50% 50%',
        repeat: -1,
        paused: true,
        ease: Linear.easeNone,
      });

      el.addEventListener('mouseenter', () => tween.play());
      el.addEventListener('mouseleave', () => tween.pause());
    }
  }
}
