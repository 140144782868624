import { highlight, delegate, fixedEncodeURIComponent } from '../../helper/utils';

const c = 'NEhpUkFoUkRtUXp2OWJzajJERjlrb05zOVdFL04zdzIzN1dUaEY4S3llOD0';
const m = 'QkZhenJIckdibUw2YXlxNFFQR0pmVkFHWHRGNGhzQjJ6bTdDTy92RU42bz0';
const lang = document.documentElement.lang || 'de';

class SiteSearch {
  sendSearchRequest = query => (
    fetch(`/veo/ajaxRequest.php?lang=${lang}&c=${c}&m=${m}&searchtext=${query}`, {
      method: 'GET',
    }).then(resp => resp.json())
  )

  constructor(el) {
    this.el = el;
    this.inputValue = '';
    this.resultsVisible = false;
    this.url = this.el.getAttribute('data-url');
    this.noresults = this.el.getAttribute('data-noresults');
    this.queryName = this.el.getAttribute('data-query-name') || 'searchtext';
    this.placeholder = this.el.getAttribute('data-placeholder') || '';
    this.itemClass = 'site-search-item';
    this.searchOpenEvent = new CustomEvent('siteSearch:open');
    this.searchCloseEvent = new CustomEvent('siteSearch:close');

    // create form
    this.input = document.createElement('input');
    this.input.classList.add('site-search-input');
    this.input.type = 'text';
    this.input.name = this.queryName;
    this.input.autocomplete = 'off';
    this.input.placeholder = this.placeholder;
    this.form = document.createElement('form');
    this.form.action = this.url;
    this.form.method = 'get';
    this.form.appendChild(this.input);

    // create results wrapper
    this.results = document.createElement('div');
    this.results.classList.add('site-search-results');

    // append elements
    this.el.appendChild(this.form);
    this.el.appendChild(this.results);

    // show search results element if there are already some
    this.input.addEventListener('focus', () => {
      if (this.input.value.length > 1) {
        this.resultsVisible = true;
        this.results.classList.add('visible');
        this.results.setAttribute('aria-hidden', true);
        this.el.dispatchEvent(this.searchOpenEvent);
      }
    });

    // focus first result item on arrow key down
    this.input.addEventListener('keydown', (e) => {
      if (this.resultsVisible === true && e.keyCode === 40) {
        e.preventDefault();

        this.results.children[0].focus();
        this.inputValue = this.input.value;
        this.input.value = this.results.children[0].textContent;
      }
    });

    this.input.addEventListener('input', () => {
      const query = this.input.value;
      this.inputValue = query;

      if (query.length > 2) {
        // if preview not yet visible, make it
        if (this.resultsVisible === false) {
          this.results.classList.add('visible');
          this.results.setAttribute('aria-hidden', false);
          this.resultsVisible = true;
          this.el.dispatchEvent(this.searchOpenEvent);
        }

        this.sendSearchRequest(fixedEncodeURIComponent(query)).then((data) => {
          // remove search results
          this.results.textContent = '';
          this.addHighlightedResults(query, data);
        }).catch((error) => {
          throw new Error(error);
        });
      } else {
        if (this.resultsVisible === true) {
          this.el.dispatchEvent(this.searchCloseEvent);
        }

        this.results.textContent = '';
        this.results.setAttribute('aria-hidden', true);
        this.resultsVisible = false;
      }
    });

    delegate(this.results, 'keydown', `.${this.itemClass}`, (e) => {
      e.preventDefault();

      const element = e.target;

      // submit form on either space or enter
      if (e.keyCode === 32 || e.keyCode === 13) {
        this.form.submit();
      }

      // down
      if (e.keyCode === 40) {
        const next = element.nextElementSibling;
        if (next !== null) {
          next.focus();
          this.input.value = next.textContent;
        }

        // up
      } else if (e.keyCode === 38) {
        const prev = element.previousElementSibling;
        if (prev !== null) {
          prev.focus();
          this.input.value = prev.textContent;

          // focus input if first child
        } else {
          this.input.focus();
          this.input.value = this.inputValue;
        }
      }
    });
  }

  addHighlightedResults(query, response, addidionalData = []) {
    if (response.length === 0 && this.noresults !== null) {
      const item = document.createElement('span');
      item.classList.add(this.itemClass);
      item.textContent = this.noresults;
      this.results.appendChild(item);
      return;
    }

    for (let i = 0; i < response.length; i++) {
      let item;

      // if there is a link create an <a> tag
      if ('link' in response[i]) {
        item = document.createElement('a');
        item.setAttribute('href', response[i].link);
      } else {
        item = document.createElement('span');
        item.setAttribute('tabindex', 0);
      }

      item.classList.add(this.itemClass);
      item.innerHTML = highlight(response[i].text, query);

      // add additional data as data attributes
      for (let j = 0; j < addidionalData.length; j++) {
        item.setAttribute(`data-${addidionalData[j]}`, response[i][addidionalData[j]]);
      }

      // add element to container
      this.results.appendChild(item);
    }
  }
}

// Define the new element
export default SiteSearch;